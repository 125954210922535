<template>
  <div>
    <header-slot />
    <searchPayments />
  </div>
</template>

<script>
// service
import searchPayments from '@/views/commons/components/charge-back_v2/view/modal/SearchPayment.vue';

export default {
  components: {
    searchPayments,
  },

  data() {
    return {
    };
  },
  computed: {
  },

  watch: {
  },
  mounted() {
  },

  methods: {
  },
};
</script >
<style scoped>
.my-bg-white {
  background-color: #ffffff;
}
.my-bg-white-dark {
  background-color: #17171a;
}
</style>
