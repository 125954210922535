<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refRiskyClients'].refresh()"
    >
      <template #table>
        <b-table
          ref="refRiskyClients"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchRiskyClients"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- <template v-slot:cell(client)="data">
            <div class="blue-color">
              <h5 style="color: #0aafdb">
                {{ data.item.client }}
              </h5>
            </div>
            <div>{{ data.item.mobile }}</div>

            <div>{{ data.item.account }}</div>
          </template> -->
          <template #cell(client)="data">
            <b-row>
              <b-col>
                <router-link
                  target="_blank"
                  :to="{
                    name: dashboardRouteName,
                    params: { idClient: `${data.item.id_account}` },
                  }"
                >
                  {{ data.item.client }}
                </router-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ data.item.account }}
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(captured_by)="data">
            <div>
              {{ data.item.captured }}
            </div>
          </template>

          <template v-slot:cell(seller)="data">
            <div>
              {{ data.item.seller }}
            </div>
          </template>
          <template v-slot:cell(fee)="data">
            <div>$ {{ data.item.fee }}</div>
          </template>
          <template v-slot:cell(ip)="data">
            <div>$ {{ data.item.initial_amount }}</div>
          </template>

          <template v-slot:cell(risky)="data">
            <div
              @click="openModalInsertCommentRisky(data.item)"
              v-b-tooltip.hover.top="'View Tracking'"
              class="cursor-pointer"
            >
              <b-badge
                style="width: 100px"
                :variant="getRiskyBadgeVariant(data.item.level)"
                >{{ getRiskyLevelName(data.item.level) }}</b-badge
              >
            </div>
          </template>

          <template v-slot:cell(created)="data">
            <div>{{ data.item.approved | myGlobal }}</div>
          </template>

          <template v-slot:cell(approved)="data">
            <div>
              {{ data.item.created_risk_at | myGlobal }}
              <br />
              {{ data.item.created_risk }}
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-insert-comments-risky
      v-if="modalInsertCommentsRiskyOn"
      :data="comments"
      @close="closeModalInsertCommentRisky"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RiskyService from "@/views/management/views/risky-clients/risky.service";
import ModalInsertCommentsRisky from "@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue";
import filterData from "@/views/management/views/risky-clients/filters.data";

export default {
  components: {
    ModalInsertCommentsRisky,
  },

  data() {
    return {
      filters: filterData,
      comments: "",
      modalInsertCommentsRiskyOn: false,
      status: null,
      captured: null,
      seller: null,
      program: null,
      statusip: null,
      sourcesname_id: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of clients...",
        model: "",
      },
      fields: [
        {
          key: "client",
          label: "client",
        },
        {
          key: "captured_by",
          label: "Captured By",
        },
        {
          key: "seller",
          label: "Seller",
        },
        {
          key: "fee",
          label: "fee",
        },
        {
          key: "ip",
          label: "IP",
        },

        {
          key: "risky",
          label: "Risky Status",
          thStyle: "width: 120px; text-align: center;",
        },

        {
          key: "created",
          label: "Client Approved",
        },
        {
          key: "approved",
          label: "Risky Created",
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      mood: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
  },
  methods: {
    openModalInsertCommentRisky(data) {
      this.modalInsertCommentsRiskyOn = true;
      this.comments = data;
    },
    closeModalInsertCommentRisky() {
      this.modalInsertCommentsRiskyOn = false;
    },

    getRiskyBadgeVariant(level) {
      switch (level) {
        case 1:
          return "info";

        case 2:
          return "warning";

        case 3:
          return "danger";

        default:
          return "success";
      }
    },

    getRiskyLevelName(level) {
      switch (level) {
        case 1:
          return "RISK - LOW";

        case 2:
          return "RISK - MEDIUM";

        case 3:
          return "RISK - HIGH";

        default:
          return "NO RISK";
      }
    },

    async searchRiskyClients(ctx) {
      const params = {
        per_page: ctx.perPage,
        orderby: 13,
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? "desc" : "asc",
        text: this.filterPrincipal.model,
        from_risk: this.filters[0].model,
        to_risk: this.filters[1].model,
        status: this.status,
        cr: this.cr,
        program: this.program,
        state_h: this.state_h,
        captured: this.captured == null ? null : this.captured,
        seller: this.seller == null ? null : this.seller,
        type_risky: 1,
        type_risky_complete: 2,
        rolsession: this.currentUser.role_id,
        statusip: this.statusip,
        sourcesname_id: this.sourcesname_id,
        program: 3,
      };
      const data = await RiskyService.getRiskyClients(params);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
  },
};
</script>

<style scoped>
.bigger {
  width: 1.35em !important;
  height: 1.35em !important;
}
.pointer {
  cursor: pointer;
}
</style>
